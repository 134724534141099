import React, { FunctionComponent } from "react"
import Layout from "../components/layout/layout"
import { graphql } from "gatsby"
import { Post } from "../models/post"
import Subheader from "../components/subheader/subheader"
import SEO from "../components/seo/seo"
import PostGrid from "../components/post-grid/post-grid"

interface PostsListPage {
  data: {
    allPosts: {
      edges: Array<{ node: Post }>
    }
  }
  location: Location
}

const ArchivePage: FunctionComponent<PostsListPage> = ({ data, location }) => {
  const posts = data.allPosts.edges.map(node => node.node) as Post[]

  return (
    <Layout bigHeader={false}>
      <SEO location={location} title={`Todos os posts`} type={`Series`} />
      <Subheader title={`Todos os posts`} subtitle={`${posts.length} posts`} />
      <PostGrid posts={posts} />
    </Layout>
  )
}

export default ArchivePage

export const query = graphql`
  query {
    allPosts: allMarkdownRemark(
      sort: { fields: frontmatter___createdDate, order: DESC }
    ) {
      edges {
        node {
          id
          frontmatter {
            title
            path
            categories
            description
            createdDate
            createdDatePretty: createdDate(
              formatString: "DD MMMM, YYYY"
              locale: "pt-br"
            )
            featuredImageAlt
            featuredImage {
              childImageSharp {
                gatsbyImageData(
                  width: 600
                  placeholder: BLURRED
                  formats: [WEBP]
                  quality: 60
                )
              }
            }
          }
        }
      }
    }
  }
`
